import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const AcceptablePage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>Acceptable use policy</h2>
            <br />
            <p>
              This acceptable use policy (“Policy”) sets forth the general
              guidelines and acceptable and prohibited uses of the
              tekifit.com website (“Website”), “www.tekifit.com”
              mobile application (“Mobile Application”) and any of their related
              products and services (collectively, “Services”). This Policy is a
              legally binding agreement between you (“User”, “you” or “your”)
              and Tekifit (“Tekifit”, “we”, “us” or “our”). If you are
              entering into this agreement on behalf of a business or other
              legal entity, you represent that you have the authority to bind
              such entity to this agreement, in which case the terms “User”,
              “you” or “your” shall refer to such entity. If you do not have
              such authority, or if you do not agree with the terms of this
              agreement, you must not accept this agreement and may not access
              and use the Services. By accessing and using the Services, you
              acknowledge that you have read, understood, and agree to be bound
              by the terms of this Agreement. You acknowledge that this
              Agreement is a contract between you and Tekifit, even though
              it is electronic and is not physically signed by you, and it
              governs your use of the Services.
            </p>
            <br />
            <h2>Prohibited activities and uses</h2>
            <br />
            <p>
              You may not use the Services to publish content or engage in
              activity that is illegal under applicable law, that is harmful to
              others, or that would subject us to liability, including, without
              limitation, in connection with any of the following, each of which
              is prohibited under this Policy:
            </p>

            <ul>
              <li>
                <p>Distributing malware or other malicious code.</p>
              </li>
              <li>
                <p>Disclosing sensitive personal information about others.</p>
              </li>
              <li>
                <p>
                  Collecting, or attempting to collect, personal information
                  about third parties without their knowledge or consent
                </p>
              </li>
              <li>
                <p>Distributing pornography or adult related content.</p>
              </li>
              <li>
                <p>
                  Promoting or facilitating prostitution or any escort services.
                </p>
              </li>
              <li>
                <p>
                  Hosting, distributing or linking to child pornography or
                  content that is harmful to minors.
                </p>
              </li>
              <li>
                <p>
                  Promoting or facilitating gambling, violence, terrorist
                  activities or selling weapons or ammunition.
                </p>
              </li>
              <li>
                <p>
                  Engaging in the unlawful distribution of controlled
                  substances, drug contraband or prescription medications.
                </p>
              </li>
              <li>
                <p>
                  Managing payment aggregators or facilitators such as
                  processing payments on behalf of other businesses or
                  charities.
                </p>
              </li>
              <li>
                <p>
                  Facilitating pyramid schemes or other models intended to seek
                  payments from public actors.
                </p>
              </li>
              <li>
                <p>
                  Threatening harm to persons or property or otherwise harassing
                  behavior.
                </p>
              </li>
              <li>
                <p>
                  Manual or automatic credit card or other available payment
                  methods testing using bots or scripts.
                </p>
              </li>
              <li>
                <p>
                  Misrepresenting or fraudulently representing products or
                  services.
                </p>
              </li>
              <li>
                <p>
                  Infringing the intellectual property or other proprietary
                  rights of others.
                </p>
              </li>
              <li>
                <p>
                  Facilitating, aiding, or encouraging any of the above
                  activities through the Services.
                </p>
              </li>
            </ul>
            <br />
            <h2>System abuse</h2>
            <br />
            <p>
              Any User in violation of the Services security is subject to
              criminal and civil liability, as well as immediate account
              termination. Examples include, but are not limited to the
              following:
            </p>
            <ul>
              <li>
                <p>
                  Use or distribution of tools designed for compromising
                  security of the Services.
                </p>
              </li>
              <li>
                <p>
                  Intentionally or negligently transmitting files containing a
                  computer virus or corrupted data.
                </p>
              </li>
              <li>
                <p>
                  Accessing another network without permission, including to
                  probe or scan for vulnerabilities or breach security or
                  authentication measures.
                </p>
              </li>
              <li>
                <p>
                  Unauthorized scanning or monitoring of data on any network or
                  system without proper authorization of the owner of the system
                  or network.
                </p>
              </li>
            </ul>

            <br />
            <h2>Service resources</h2>
            <br />
            <p>
              You may not consume excessive amounts of the resources of the
              Services or use the Services in any way which results in
              performance issues or which interrupts the Services for other
              Users. Prohibited activities that contribute to excessive use,
              include without limitation:
            </p>
            <ul>
              <li>
                <p>
                  Deliberate attempts to overload the Services and broadcast
                  attacks (i.e. denial of service attacks).
                </p>
              </li>
              <li>
                <p>
                  Engaging in any other activities that degrade the usability
                  and performance of the Services.
                </p>
              </li>
            </ul>
            <br />
            <h2>No spam policy</h2>
            <p>
              You may not use the Services to send spam or bulk unsolicited
              messages. We maintain a zero tolerance policy for use of the
              Services in any manner associated with the transmission,
              distribution or delivery of unsolicited bulk or unsolicited
              commercial e-mail, or the sending, assisting, or commissioning the
              transmission of commercial e-mail that does not comply with the
              U.S. CAN-SPAM Act of 2003 (“SPAM”).
            </p>
            <p>
              Your products or services advertised via SPAM (i.e. Spamvertised)
              may not be used in conjunction with the Services. This provision
              includes, but is not limited to, SPAM sent via fax, phone, postal
              mail, email, instant messaging, or newsgroups.
            </p>
            <p>
              We may terminate the Service of any User who sends out SPAM with
              or without notice.
            </p>
            <br />
            <h2>Defamation and objectionable content</h2>
            <br />
            <p>
              We value the freedom of expression and encourage Users to be
              respectful with the content they post. We are not a publisher of
              User content and are not in a position to investigate the veracity
              of individual defamation claims or to determine whether certain
              material, which we may find objectionable, should be censored.
              However, we reserve the right to moderate, disable or remove any
              content to prevent harm to others or to us or the Services, as
              determined in our sole discretion.
            </p>
            <br />
            <h2>Copyrighted content</h2>
            <br />
            <p>
              Copyrighted material must not be published via the Services
              without the explicit permission of the copyright owner or a person
              explicitly authorized to give such permission by the copyright
              owner. Upon receipt of a claim for copyright infringement, or a
              notice of such violation, we may, at our discretion, run an
              investigation and, upon confirmation, may remove the infringing
              material from the Services. We may terminate the Service of Users
              with repeated copyright infringements. Further procedures may be
              carried out if necessary. We will assume no liability to any User
              of the Services for the removal of any such material. If you
              believe your copyright is being infringed by a person or persons
              using the Services, please get in touch with us to report
              copyright infringement.
            </p>
            <br />
            <h2>Security</h2>
            <br />
            <p>
              You take full responsibility for maintaining reasonable security
              precautions for your account. You are responsible for protecting
              and updating any login account provided to you for the Services.
              You must protect the confidentiality of your login details, and
              you should change your password periodically.
            </p>
            <br />
            <h2>Enforcement</h2>
            <br />
            <p>
              We reserve our right to be the sole arbiter in determining the
              seriousness of each infringement and to immediately take
              corrective actions, including but not limited to:
            </p>
            <ul>
              <li>
                <p>
                  Suspending or terminating your Service with or without notice
                  upon any violation of this Policy. Any violations may also
                  result in the immediate suspension or termination of your
                  account.
                </p>
              </li>
              <li>
                <p>
                  Disabling or removing any content which is prohibited by this
                  Policy, including to prevent harm to others or to us or the
                  Services, as determined by us in our sole discretion.
                </p>
              </li>
              <li>
                <p>
                  Reporting violations to law enforcement as determined by us in
                  our sole discretion.
                </p>
              </li>
              <li>
                <p>
                  A failure to respond to an email from our abuse team within 2
                  days, or as otherwise specified in the communication to you,
                  may result in the suspension or termination of your account.
                </p>
              </li>
            </ul>
            <br />
            <h2>
              Suspended and terminated User accounts due to violations will not
              be re-activated.
            </h2>
            <br />
            <p>
              Nothing contained in this Policy shall be construed to limit our
              actions or remedies in any way with respect to any of the
              prohibited activities. We reserve the right to take any and all
              additional actions we may deem appropriate with respect to such
              activities, including without limitation taking action to recover
              the costs and expenses of identifying offenders and removing them
              from the Services, and levying cancellation charges to cover our
              costs. In addition, we reserve at all times all rights and
              remedies available to us with respect to such activities at law or
              in equity.
            </p>
            <br />
            <h2>Reporting violations</h2>
            <br />
            <p>
              If you have discovered and would like to report a violation of
              this Policy, please contact us immediately. We will investigate
              the situation and provide you with full assistance.
            </p>
            <br />
            <h2>Changes and amendments</h2>
            <br />
            <p>
              We reserve the right to modify this Policy or its terms related to
              the Services at any time at our discretion. When we do, we will
              revise the updated date at the bottom of this page, post a
              notification within the Services. We may also provide notice to
              you in other ways at our discretion, such as through the contact
              information you have provided.
            </p>
            <p>
              An updated version of this Policy will be effective immediately
              upon the posting of the revised Policy unless otherwise specified.
              Your continued use of the Services after the effective date of the
              revised Policy (or such other act specified at that time) will
              constitute your consent to those changes.
            </p>
            <br />
            <h2>Acceptance of this policy</h2>
            <br />
            <p>
              You acknowledge that you have read this Policy and agree to all
              its terms and conditions. By accessing and using the Services you
              agree to be bound by this Policy. If you do not agree to abide by
              the terms of this Policy, you are not authorized to access or use
              the Services.
            </p>
            <br />
            <h2>Contacting us</h2>
            <br />
            <p>
              If you have any questions, concerns, or complaints regarding this
              Policy, we encourage you to contact us using the details below:
            </p>
            <p>SANTIAGO@TECHFRENETIC.COM</p>
            <span>This document was last updated on January 07, 2025</span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default AcceptablePage;
