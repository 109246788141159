import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ico_others_options from "../../_images/ico-others-options.svg";
import ico_delete from "../../_images/ico-delete-mycontent.svg";
import { articleService } from "../../_services";
import dateFormat from "dateformat";

const extractDate = (htmlDate) => {
  if (!htmlDate) return null;
  const match = htmlDate.match(/datetime="([^"]+)"/); 
  return match ? match[1] : null;
};

export default function SaveArticles() {
  const { t, i18n } = useTranslation("common");
  const [listPostSave, setListPostSave] = useState([]);
  const lang = i18n.language;
  const { id } = JSON.parse(localStorage.getItem("me"));

  useEffect(() => {
    if (id) {
      articleService
        .getListSaveArticles(lang, id)
        .then((res) => res.json())
        .then((response) => {
          setListPostSave(response.articles);
        })
        .catch((error) => console.log(error));
    }
  }, [setListPostSave, id]);

  const handleSave = (articleId, userId) => {
    articleService.postUnSaveArticle(articleId, userId);
  }

  return (
    <div className="profile__dashboard my__content">
      <h2 className="dashboard__title">
        <span className="my__content__title">{t("menus.saved_articles")}</span>
        <span className="title__counter">
          {" "}
          {`${listPostSave && listPostSave.length} ${t("profile.articles")}`}
        </span>
      </h2>
      <div className="my__articles__content">
        {listPostSave && listPostSave.map((article) => (
          <article key={article.id}>
            {/* Interactions section */}
            <div className="article__interactions d-flex justify-content-end">
              <div className="others__links dropdown">
                <a href="#" className="others__shares">
                  <img src={ico_others_options} alt="Options" />
                </a>
                <ul className="share__options dropdown-list" id="shared__list8">
                  <div className="share__links">
                    <a href="#" className="delete__content" onClick={() => handleSave(article.id, id)} >
                      <img src={ico_delete} alt="Delete content" />
                      <span>Delete</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>

            {/* Article information section */}
            <div className="d-flex">
              <div className="article__information col-7">
                <div className="article__cat d-flex">
                  <p className="article__cat__tag" rel="category">
                    <a href="#">{article.category}</a>
                  </p>
                  <span className="article__cat__separator">•</span>
               <time className="article__hour__tag" itemProp="datePublished">
                 {article.date ? dateFormat(new Date(extractDate(article.date)), "dS mmm yyyy, h:MM TT") : ''}
                </time>
                </div>
                <h2 itemProp="headline">
                  <a href={`/${lang}/${article.id}`}>{article.title}</a>
                </h2>
                <div className="autor__container d-flex">
                  <p className="article__autor">
                    <a href="/">
                      <span itemProp="name">{article.user}</span>
                    </a>
                  </p>
                </div>
              </div>

              {/* Article image section */}
              <figure className="article__image__content col-5">
               <a href="/">
                <img
                  itemProp="image"
                  src={article.thumbnail}
                  alt="AI development is easier than you think.
                                    See Why."
                />
              </a>
              </figure>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}
