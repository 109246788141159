import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const GuestPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>Guest post agreement</h2>
            <br />
            <p>
              Thank you for showing interest in writing a guest post for us.
              We’re glad you’re here.
            </p>
            <p>
              Please take a moment to review this entire page as it explains the
              guidelines, the submission process, and other important details.
            </p>
            <p>
              This will ensure that your submission meets what we’re looking for
              and improve your chances of getting the article published on our
              website.
            </p>
            <br />
            <h2>Article guidelines</h2>
            <br />
            <p>
              Before sending us your article, make sure it follows these
              guidelines:
            </p>
            <ul>
              <li>
                <p>Article must have a minimum of 500 words</p>
              </li>
              <li>
                <p>Article must be free of spelling mistakes</p>
              </li>
              <li>
                <p>Article must be free of plagiarism</p>
              </li>
              <li>
                <p>
                  Article must not violate any copyrights, patents, or
                  trademarks
                </p>
              </li>
              <li>
                <p>
                  Article must not contain unlawful, hateful, and threatening
                  language
                </p>
              </li>
              <li>
                <p>Article must not contain profanity or vulgar language</p>
              </li>
              <li>
                <p>
                  Article may include relevant pictures and videos with proper
                  attributions, permission to use, or related licenses
                </p>
              </li>
              <li>
                <p>
                  You may include links in the article as long as they make
                  sense (no affiliate links)
                </p>
              </li>
              <li>
                <p>Article must be submitted in the Plain Text format</p>
              </li>
            </ul>
            <p>You may email your article to http://www.tekifit.com</p>
            <br />
            <h2>Ownership and credit</h2>
            <br />
            <p>
              If your article is accepted and published, your information will
              appear along with it. As such, make sure to also include a photo
              of yourself along with your article submission.
            </p>
            <p>
              Everything submitted and published by us will remain your
              property. You understand and agree that you give us an exclusive
              right to use the article and related materials as per this
              agreement. However, you are allowed to republish the article on
              your own website at any time after it’s been published on our
              website.
            </p>
            <br />
            <h2>Review process</h2>
            <br />
            <p>
              We’ll do our best to review all submitted articles as quickly as
              possible. However, due to a large number of article submissions we
              receive on a regular basis, it may take a while to get back to you
              so please be patient and don’t resubmit the same article multiple
              times.
            </p>
            <p>
              Not all submitted articles will be accepted. We reserve the right
              to refuse any article at our sole discretion. If your article is
              not accepted, we’ll get in touch with you to let you know so you
              could possibly make another submission.
            </p>
            <br />
            <h2>Article publishing</h2>
            <p>
              If your article is accepted, it will be added to our publication
              queue and published according to its schedule.
            </p>
            <br />
            <h2>Promotion and engagement</h2>
            <br />
            <p>
              Once your article is published, we’ll promote it to our audience
              for wider exposure and better engagement. This may include sending
              a newsletter, posting on your social media channels, making an
              announcement or linking to it from your own website.
            </p>
            <br />
            <h2>Legal disclaimer</h2>
            <br />
            <p>
              This guest post agreement (“Agreement”) sets forth the general
              guidelines, requirements, terms, conditions, rights, and
              obligations when you (“Writer”, “you” or “your”) wish to submit a
              guest post article (“Article”) for the tekifit.com website
              (“website”). This Agreement is legally binding between the you and
              Tekifit (“Tekifit”, “we”, “us” or “our”).
            </p>
            <p>
              If you are entering into this agreement on behalf of a business or
              other legal entity, you represent that you have the authority to
              bind such entity to this agreement, in which case the terms
              “writer”, “you” or “your” shall refer to such entity. If you do
              not have such authority, or if you do not agree with the
              guidelines, terms, conditions, rights, or obligations, you must
              not accept this agreement and may not submit any articles.
            </p>
            <p>
              By submitting an article for publishing on the website, you
              acknowledge that you have read, understood, and agree to be bound
              by the terms of this Agreement. You acknowledge that this
              Agreement is a contract between you and Tekifit, even though
              it is electronic and is not physically signed by you, and it
              governs your article submissions and publishings.
            </p>
            <br />
            <h2>Defamation and objectionable content</h2>
            <br />
            <p>
              We value the freedom of expression and encourage Users to be
              respectful with the content they post. We are not a publisher of
              User content and are not in a position to investigate the veracity
              of individual defamation claims or to determine whether certain
              material, which we may find objectionable, should be censored.
              However, we reserve the right to moderate, disable or remove any
              content to prevent harm to others or to us or the Services, as
              determined in our sole discretion.
            </p>
            <ul>
              <li>
                Independent contractor status
                <p>
                  Nothing contained in this Agreement shall be interpreted as
                  creating or establishing any partnership or joint venturers.
                  You acknowledge that you act as an independent contractor. You
                  are not considered an employee and are not entitled to
                  participate in any employee plans, arrangements, or
                  distributions by Tekifit. You must not act as an agent of
                  Tekifit and must not provide any services under the name
                  of Tekifit.
                </p>
                <p>
                  You must not under any circumstances (i) enter into any
                  agreements on behalf of Tekifit, (ii) incur any
                  obligations on behalf of Tekifit, (iii) act for or to
                  bind Tekifit in any way, (iv) sign the name of
                  Tekifit, (v) represent that Tekifit is in any way
                  responsible for your acts or omissions (vi) refer to
                  Tekifit as a customer in any manner or format, or (vii)
                  use our logo or name in a way that implies that you and
                  Tekifit are partners or that we have endorsed you, your
                  website or your products or services. You must obtain express
                  written permission for any use of the logos, trademarks, or
                  other intellectual property that belong to Tekifit.
                </p>
              </li>
              <li>
                Ownership of intellectual property
                <p>
                  Upon submitting an Article, you transfer all of its ownership
                  rights to Tekifit and give Tekifit a perpetual,
                  irrevocable, worldwide, non-exclusive, royalty-free,
                  transferable, and fully sub-licensable right and license to
                  reproduce, distribute, publicly display, use, perform, make
                  derivative works of or otherwise use the Article and likeness
                  in any form, media or technology, now known or later developed
                  for any purpose including commercial purposes.
                </p>
              </li>
              <li>
                Limitation of liability
                <p>
                  To the fullest extent permitted by applicable law, in no event
                  will Tekifit, its affiliates, directors, officers,
                  employees, agents, suppliers or licensors be liable to any
                  person for any indirect, incidental, special, punitive, cover,
                  or consequential damages (including, without limitation,
                  damages for lost profits, revenue, sales, goodwill, use of the
                  content, impact on business, business interruption, loss of
                  anticipated savings, loss of business opportunity) however
                  caused, under any theory of liability, including, without
                  limitation, contract, tort, warranty, breach of statutory
                  duty, negligence or otherwise, even if the liable party has
                  been advised as to the possibility of such damages or could
                  have foreseen such damages. You assume all legal liability for
                  the accuracy, scope, quality, and any possible outcomes as a
                  result of or relating to your Article.
                </p>
              </li>
              <li>
                Indemnification
                <p>
                  You agree to indemnify and hold Tekifit and its
                  affiliates, directors, officers, employees, agents, suppliers,
                  and licensors harmless from and against any liabilities,
                  losses, damages, or costs, including reasonable attorneys’
                  fees, incurred in connection with or arising from any third
                  party allegations, claims, actions, disputes, or demands
                  asserted against any of them as a result of or relating to
                  your Article, your use of the Services or any willful
                  misconduct on your part.
                </p>
              </li>
              <li>
                Severability
                <p>
                  All rights and restrictions contained in this Agreement may be
                  exercised and shall be applicable and binding only to the
                  extent that they do not violate any applicable laws and are
                  intended to be limited to the extent necessary so that they
                  will not render this Agreement illegal, invalid or
                  unenforceable. If any provision or portion of any provision of
                  this Agreement shall be held to be illegal, invalid, or
                  unenforceable by a court of competent jurisdiction, it is the
                  intention of the parties that the remaining provisions or
                  portions thereof shall constitute their agreement with respect
                  to the subject matter hereof, and all such remaining
                  provisions or portions thereof shall remain in full force and
                  effect.
                </p>
              </li>
              <li>
                Dispute of resolution
                <p>
                  The formation, interpretation, and performance of this
                  Agreement and any disputes arising out of it shall be governed
                  by the substantive and procedural laws of Colombia without
                  regard to its rules on conflicts or choice of law and, to the
                  extent applicable, the laws of Colombia. The exclusive
                  jurisdiction and venue for actions related to the subject
                  matter hereof shall be the courts located in Colombia, and you
                  hereby submit to the personal jurisdiction of such courts. You
                  hereby waive any right to a jury trial in any proceeding
                  arising out of or related to this Agreement. The United
                  Nations Convention on Contracts for the International Sale of
                  Goods does not apply to this Agreement.
                </p>
              </li>
              <li>
                Assignment
                <p>
                  You may not assign, resell, sub-license or otherwise transfer
                  or delegate any of your rights or obligations hereunder, in
                  whole or in part, without our prior written consent, which
                  consent shall be at our own sole discretion and without
                  obligation; any such assignment or transfer shall be null and
                  void. We are free to assign any of its rights or obligations
                  hereunder, in whole or in part, to any third party as part of
                  the sale of all or substantially all of its assets or stock or
                  as part of a merger.
                </p>
              </li>
              <li>
                Changes and amendments
                <p>
                  We reserve the right to modify this Agreement or its terms at
                  any time at our discretion. When we do, we will revise the
                  updated date at the bottom of this page. An updated version of
                  this Agreement will be effective immediately upon the posting
                  of the revised Agreement unless otherwise specified. Your
                  future Article submissions after the effective date of the
                  revised Agreement (or such other act specified at that time)
                  will constitute your consent to those changes.
                </p>
              </li>
              <li>
                Acceptance of these terms
                <p>
                  You acknowledge that you have read this Agreement and agree to
                  all its terms and conditions. By submitting an Article for
                  publishing on the website, you agree to be bound by this
                  Agreement. If you do not agree to abide by the terms of this
                  Agreement, you must not accept this agreement and may not
                  submit any Articles.
                </p>
              </li>
            </ul>
            <br />
            <h2>Contacting us</h2>
            <br />
            <p>
              If you have any questions, concerns, or complaints regarding this
              Agreement, we encourage you to contact us using the details below:
            </p>
            <p>SANTIAGO@TECHFRENETIC.COM</p>
            <span>This document was last updated on January 07, 2025</span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default GuestPage;
