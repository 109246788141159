import React from "react";
import { Link } from "@reach/router";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

export default function NosotrosPagina() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="body__text about">
          <div className="container">
            <div class="comment__style__box">
              <div class="inner">
                <h1>Hola, somos Tekifit.</h1>
              </div>
              <div class="triangle"></div>
            </div>
            <p>
              Estábamos en Delaware USA, esperando al CTO de una empresa local
              para presentar una propuesta sobre CiberSeguridad, cuando llegó
              nos dijo: "Perdón por la demora, a veces paso tanto tiempo
              buscando respuestas, soluciones y proveedores confiables en
              internet que yo estar todo el día sin conseguirlo, lo peor es que
              hay tanta información que me siento perdido"... fue tan fácil
              entender, de hecho, algo similar le sucedía constantemente a a
              nosotros.
            </p>
            <p>
              Hablamos de ello después en una noche de cervezas entre amigos de
              el sector tecnológico, todos coincidimos en la misma idea:
            </p>
            <blockquote>
              Trabajar en este sector desde cualquier posición como ingeniero de
              producción, director de tecnología, desarrollador o especialista
              técnico, todos sufren al tratar de encontrar soluciones en
              innumerables portales web sin sentir total confianza, asombrado
              por tantos pagados sitios de comparación con publicidad y tantos
              comentarios de buena fe con información no comprobable. Tomar una
              buena decisión parece una práctica de prueba y error”.
            </blockquote>
            <p>
              Nos dimos cuenta de que uno de los factores que los clientes que
              visitamos Lo que más me gustó fue la forma en que explicamos la
              solución que estábamos presentando, no queríamos vender al cliente
              equivocado, así que lo hicimos con casos reales y comparando la
              solución con las que existía en el mercado y la conveniencia de
              cada uno.
            </p>
            <p>
              Nos dijeron que les encantaría que todas sus decisiones pudieran
              hacerse en base a una comparación realista y objetiva, viendo la
              lado positivo y negativo de cada uno y entendiendo que cada uno La
              solución puede ser la mejor dependiendo de la situación.
            </p>
            <hr />
            <h2>¿Y si creamos un portal dedicado a eso?</h2>
            <p>
              Podemos explicar desde nuestra experiencia real, soluciones
              prácticas dando una visión comparativa para tomar decisiones,
              entender oportunidades y amenazas.
            </p>
            <p>
              Luego nos dimos cuenta de que no solo podía ser creado por nuestro
              equipo, nosotros perdería la experiencia de tantos amigos y
              expertos en el sector. Entonces nació Tekifit como un portal
              social para el Teki comunidad.
            </p>
            <p>
              La historia apenas comienza, pero la intención, la investigación y
              los colaboradores es un tema que ha avanzado.
            </p>
            <div class="comment__style__box present">
              <div class="inner">
                <p>
                  Eso es lo que te presentamos ahora, si quieres formar parte de
                  esto, es tu decision, si solo quieres leer, lo haremos sé
                  feliz de convertirte en esa fuente de información que buscas
                  por.
                </p>
              </div>
              <div class="triangle"></div>
            </div>
            <div className="frenetic__diference">
              <h2>¿Cuál es el ADN de un Tekifit?</h2>
              <ul className="and__frenetic">
                <li>Nosotros somos neutrales.</li>
                <li>Somos apasionados de la tecnología.</li>
                <li>Somos amantes de la comunidad.</li>
              </ul>
              <Link
                to={`/${lang}/${t("url.community")}`}
                className="btn-primary"
              >
                Convertirse en Tekifit
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
