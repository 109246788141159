import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const AceptablePage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>Política de uso aceptable</h2>
            <br />
            <p>
              Esta política de uso aceptable ("Política") establece las normas
              generales lineamientos y usos aceptables y prohibidos del Sitio
              web Tekifitcom ("Sitio web"), "www.tekifit.com"
              aplicación móvil ("Aplicación móvil") y cualquiera de sus
              relacionados productos y servicios (colectivamente, "Servicios").
              Esta Política es una acuerdo legalmente vinculante entre usted
              ("Usuario", "usted" o "su") y Tekifit ("Tekifit",
              "nosotros", "nos" o "nuestro"). Si usted es celebrar este acuerdo
              en nombre de una empresa u otra entidad legal, usted declara que
              tiene la autoridad para vincular dicha entidad a este acuerdo, en
              cuyo caso los términos "Usuario", "usted" o "su" se referirá a
              dicha entidad. Si tu no tienes dicha autoridad, o si no está de
              acuerdo con los términos de este acuerdo, no debe aceptar este
              acuerdo y no puede acceder y utilizar los Servicios. Al acceder y
              utilizar los Servicios, usted reconoce que ha leído, entendido y
              acepta estar obligado por los términos de este Acuerdo. reconoces
              que esto Acuerdo es un contrato entre usted y Tekifit, aunque
              es electrónico y no está firmado físicamente por usted, y rige su
              uso de los Servicios.
            </p>
            <br />
            <h2>Actividades y usos prohibidos</h2>
            <br />
            <p>
              No puede usar los Servicios para publicar contenido o participar
              en actividad que es ilegal bajo la ley aplicable, que es dañina
              para otros, o que nos sometería a responsabilidad, incluyendo, sin
              limitación, en relación con cualquiera de los siguientes, cada uno
              de los cuales está prohibido en virtud de esta Política:
            </p>
            <ul>
              <li>
                <p>Distribuir malware u otro código malicioso.</p>
              </li>
              <li>
                <p>Divulgar información personal confidencial sobre otros.</p>
              </li>
              <li>
                <p>
                  Recopilación o intento de recopilación de información personal
                  sobre terceros sin su conocimiento o consentimiento
                </p>
              </li>
              <li>
                <p>
                  Distribuir pornografía o contenido relacionado con adultos.
                </p>
              </li>
              <li>
                <p>
                  Promover o facilitar la prostitución o cualquier servicio de
                  acompañantes.
                </p>
              </li>
              <li>
                <p>
                  Hospedar, distribuir o vincular pornografía infantil o
                  contenido que sea perjudicial para los menores.
                </p>
              </li>
              <li>
                <p>
                  Promover o facilitar el juego, la violencia, el terrorismo
                  actividades o venta de armas o municiones.
                </p>
              </li>
              <li>
                <p>
                  Participar en la distribución ilícita de bienes controlados
                  sustancias, contrabando de drogas o medicamentos recetados.
                </p>
              </li>
              <li>
                <p>
                  Gestionar agregadores o facilitadores de pagos, como
                  procesamiento de pagos en nombre de otras empresas o
                  organizaciones benéficas
                </p>
              </li>
              <li>
                <p>
                  Facilitar esquemas piramidales u otros modelos destinados a
                  buscar pagos de actores públicos.
                </p>
              </li>
              <li>
                <p>
                  Amenazar con dañar a las personas o la propiedad o acosar de
                  otra manera comportamiento.
                </p>
              </li>
              <li>
                <p>
                  Tarjeta de crédito manual o automática u otro pago disponible
                  pruebas de métodos usando bots o scripts.
                </p>
              </li>
              <li>
                <p>
                  La tergiversación o representación fraudulenta de productos o
                  servicios.
                </p>
              </li>
              <li>
                <p>
                  Infringir la propiedad intelectual u otra propiedad derechos
                  de los demás.
                </p>
              </li>
              <li>
                <p>
                  Facilitar, ayudar o alentar cualquiera de los anteriores
                  actividades a través de los Servicios.
                </p>
              </li>
            </ul>
            <br />
            <h2>Abuso del sistema</h2>
            <br />
            <p>
              Cualquier Usuario que viole la seguridad de los Servicios está
              sujeto a responsabilidad penal y civil, así como la cuenta
              inmediata terminación. Los ejemplos incluyen, pero no se limitan a
              los siguiendo:
            </p>
            <ul>
              <li>
                <p>
                  Uso o distribución de herramientas diseñadas para comprometer
                  seguridad de los Servicios.
                </p>
              </li>
              <li>
                <p>
                  La transmisión intencional o negligente de archivos que
                  contengan un virus informático o datos dañados.
                </p>
              </li>
              <li>
                <p>
                  Acceder a otra red sin permiso, incluso para sondear o
                  escanear en busca de vulnerabilidades o violar la seguridad o
                  medidas de autenticación.
                </p>
              </li>
              <li>
                <p>
                  Escaneo o monitoreo no autorizado de datos en cualquier red o
                  sistema sin la debida autorización del propietario del sistema
                  o red.
                </p>
              </li>
            </ul>
            <br />
            <h2>Recursos de servicio</h2>
            <br />
            <p>
              No puede consumir cantidades excesivas de los recursos de la
              Servicios o usar los Servicios de cualquier manera que resulte en
              problemas de rendimiento o que interrumpa los Servicios por otros
              Usuarios. Actividades prohibidas que contribuyan al uso excesivo,
              incluyen sin limitación:
            </p>
            <ul>
              <li>
                <p>
                  Intentos deliberados de sobrecargar los Servicios y transmitir
                  (es decir, ataques de denegación de servicio).
                </p>
              </li>
              <li>
                <p>
                  Participar en cualquier otra actividad que degrade la
                  usabilidad y desempeño de los Servicios.
                </p>
              </li>
            </ul>
            <br />
            <h2>Política de no spam</h2>
            <p>
              No puede usar los Servicios para enviar correo no deseado o
              mensajes no solicitados masivos. mensajes Mantenemos una política
              de tolerancia cero para el uso de la Servicios de cualquier manera
              asociados con la transmisión, distribución o entrega de productos
              a granel no solicitados o correo electrónico comercial, o el
              envío, asistencia o encargo del transmisión de correo electrónico
              comercial que no cumpla con los Ley CAN-SPAM de EE. UU. de 2003
              ("SPAM").
            </p>
            <p>
              Sus productos o servicios anunciados a través de SPAM (es decir,
              Spamvertised) no se puede utilizar junto con los Servicios. Esta
              disposición incluye, pero no se limita a, SPAM enviado por fax,
              teléfono, correo correo electrónico, correo electrónico,
              mensajería instantánea o grupos de noticias.
            </p>
            <p>
              Podemos rescindir el Servicio de cualquier Usuario que envíe SPAM
              con o sin previo aviso.
            </p>
            <br />
            <h2>Difamación y contenido censurable</h2>
            <br />
            <p>
              Valoramos la libertad de expresión y animamos a los Usuarios a ser
              respetuoso con el contenido que publica. No somos un editor de
              contenido del usuario y no están en condiciones de investigar la
              veracidad de demandas individuales por difamación o para
              determinar si ciertas el material que podamos encontrar objetable
              debe ser censurado. Sin embargo, nos reservamos el derecho de
              moderar, deshabilitar o eliminar cualquier contenido para evitar
              daños a otros o a nosotros o a los Servicios, como determinado a
              nuestro exclusivo criterio.
            </p>
            <br />
            <h2>Contenido protegido por derechos de autor</h2>
            <br />
            <p>
              El material protegido por derechos de autor no debe publicarse a
              través de los Servicios. sin el permiso explícito del propietario
              de los derechos de autor o de una persona autorizado
              explícitamente para otorgar dicho permiso por los derechos de
              autor dueño. Al recibir una reclamación por infracción de derechos
              de autor, o una notificación de tal violación, podemos, a nuestra
              discreción, realizar una investigación y, una vez confirmado,
              puede eliminar el infractor material de los Servicios. Podemos
              rescindir el Servicio de los Usuarios con repetidas infracciones
              de derechos de autor. Otros procedimientos pueden ser llevado a
              cabo si es necesario. No asumiremos ninguna responsabilidad ante
              ningún Usuario. de los Servicios para la eliminación de dicho
              material. Si usted cree que sus derechos de autor están siendo
              infringidos por una persona o personas utilizando los Servicios,
              póngase en contacto con nosotros para informar infracción de
              copyright.
            </p>
            <br />
            <h2>Seguridad</h2>
            <br />
            <p>
              Usted asume toda la responsabilidad de mantener una seguridad
              razonable precauciones por su cuenta. Usted es responsable de
              proteger y actualizar cualquier cuenta de inicio de sesión que se
              le haya proporcionado para los Servicios. Debe proteger la
              confidencialidad de sus datos de inicio de sesión, y debe cambiar
              su contraseña periódicamente.
            </p>
            <br />
            <h2>Cumplimiento</h2>
            <br />
            <p>
              Nos reservamos el derecho de ser el único árbitro en la
              determinación de la gravedad de cada infracción y tomar
              inmediatamente acciones correctivas, que incluyen pero no se
              limitan a:
            </p>
            <ul>
              <li>
                <p>
                  Suspender o cancelar su Servicio con o sin previo aviso ante
                  cualquier violación de esta Política. Cualquier violación
                  también puede resultar en la suspensión o terminación
                  inmediata de su cuenta.
                </p>
              </li>
              <li>
                <p>
                  Deshabilitar o eliminar cualquier contenido que esté prohibido
                  por este Política, incluida la prevención de daños a otros o a
                  nosotros o al Servicios, según lo determinemos a nuestro
                  exclusivo criterio.
                </p>
              </li>
              <li>
                <p>
                  Reportar violaciones a la policía según lo determinemos en
                  nuestro exclusivo criterio.
                </p>
              </li>
              <li>
                <p>
                  No responder a un correo electrónico de nuestro equipo de
                  abuso dentro de 2 días, o como se especifique de otro modo en
                  la comunicación a usted, puede resultar en la suspensión o
                  terminación de su cuenta.
                </p>
              </li>
            </ul>
            <br />
            <h2>
              Las cuentas de usuario suspendidas y rescindidas debido a
              infracciones no ser reactivado.
            </h2>
            <br />
            <p>
              Nada de lo contenido en esta Política se interpretará como una
              limitación de nuestra acciones o remedios de ninguna manera con
              respecto a cualquiera de los actividades prohibidas. Nos
              reservamos el derecho de tomar cualquiera y todos acciones
              adicionales que consideremos apropiadas con respecto a tales
              actividades, incluidas, entre otras, la adopción de medidas para
              recuperar los costos y gastos de identificar a los delincuentes y
              removerlos de los Servicios, y cobrando cargos de cancelación para
              cubrir nuestra costos Además, nos reservamos en todo momento todos
              los derechos y remedios disponibles para nosotros con respecto a
              tales actividades en la ley o en equidad.
            </p>
            <br />
            <h2>Informar de infracciones</h2>
            <br />
            <p>
              Si ha descubierto y desea denunciar una infracción de esta
              Política, contáctenos de inmediato. vamos a investigar la
              situación y brindarle asistencia completa.
            </p>
            <br />
            <h2>Cambios y enmiendas</h2>
            <br />
            <p>
              Nos reservamos el derecho de modificar esta Política o sus
              términos relacionados con los Servicios en cualquier momento a
              nuestra discreción. Cuando lo hagamos, lo haremos revise la fecha
              actualizada en la parte inferior de esta página, publique una
              notificación dentro de los Servicios. También podemos enviar un
              aviso a usted de otras maneras a nuestra discreción, como a través
              del contacto información que ha proporcionado.
            </p>
            <p>
              Una versión actualizada de esta Política entrará en vigencia de
              inmediato. tras la publicación de la Política revisada, a menos
              que se especifique lo contrario. Su uso continuado de los
              Servicios después de la fecha de vigencia de la Política revisada
              (o cualquier otro acto especificado en ese momento) constituyen su
              consentimiento a dichos cambios.
            </p>
            <br />
            <h2>Aceptación de esta política</h2>
            <br />
            <p>
              Usted reconoce que ha leído esta Política y acepta todas sus
              términos y condiciones. Al acceder y utilizar los Servicios, usted
              acepta estar sujeto a esta Política. Si no está de acuerdo en
              cumplir los términos de esta Política, usted no está autorizado a
              acceder o utilizar los servicios.
            </p>
            <br />
            <h2>Contáctanos</h2>
            <br />
            <p>
              Si tiene alguna pregunta, inquietud o queja con respecto a esta
              Política, le animamos a que se ponga en contacto con nosotros
              utilizando los detalles a continuación:
            </p>
            <p>SANTIAGO@TECHFRENETIC.COM</p>
            <span>
              Este documento fue actualizado por última vez el 07 de enero de
              2025
            </span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default AceptablePage;
