import React from "react";
import { useTranslation } from "react-i18next";

export default function Credits() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <footer className="profile__footer">
      <div className="languajes">
        <ul>
          <li>
            <a href="/en/community">English (US)</a>
          </li>
          <li className="current">
            <a href="/es/comunidad">Español</a>
          </li>
        </ul>
      </div>
      <div className="profile__others__links ads__content">
        <ul>
          <li>
            <a href={`/${lang}/${t("url.privacy")}`}>{`${t("others.policy")}`}</a>
          </li>
          <li>
            <a href={`/${lang}/${t("url.commerce")}`}>{`${t("others.commerce")}`}</a>
          </li>
          <li>
            <a href={`/${lang}/${t("others.cookie")}`}>{`${t("others.cookie")}`}</a>
          </li>
          <li>
            <div className="others__links dropdown footer__dropdown">
              <a href="/" className="others__shares footer__more dropdown">
              {t("menus.view_more")}
              </a>
              <ul className="share__options dropdown-list" id="shared__list">
                <div className="share__links">
                  <a href="/">{t("menus.contact")}</a>
                  <a href="/">{t("menus.about")}</a>
                  <a href="/">{t("menus.suscribe")}</a>
                  {/* <a href="/">Sitemap</a> */}
                </div>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <span className="copyright">© Tekifit, 2025.</span>
    </footer>
  );
}
