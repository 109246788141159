import React from "react";
import uzer_logo from "../../_images/uzer-logo-black.svg";
import { useTranslation } from "react-i18next";

export default function Copyright() {
  const { t } = useTranslation("common");

  return (
    <div className="copyright__content row">
      <p>{t("footer.copyright")}</p>
      {/* <p>
        {t("footer.designed")}
        <a href="/">
          <img itemProp="image" src={uzer_logo} alt={t("footer.uzer")} />
        </a>
      </p> */}
    </div>
  );
}
