import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { ProfileMenu } from "../_components/navigation/profile-menu";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { userService } from "../_services";

import logo from "../_images/icons/techlogo.svg";
import check from "../_images/icons/checkPremium.svg";
import "../css/premiumPage.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PremiumPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [tech, setTech] = useState({});
  const [techItems, setTechItems] = useState([]);

  useEffect(() => {
    userService
      .techPremium(lang)
      .then((res) => res.json())
      .then((response) => {
        setTech(response[0]);
        setTechItems(response[0].features);
      })
      .catch(console.log);
  }, [lang]);

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>

      <section className="premium">
        <div className="header__menu-mobile">
          <ProfileMenu selected="account" />
        </div>
        <div className="container premium__container">
          <div className="tittle__premium">
            <h1 className="highlighted text-center">
              <span>{t("premium.title_premium_span")}</span>
              {t("premium.title_premium")}
            </h1>
          </div>

          <div className="content__info_premium">
            <img src={logo} alt="logo-tech" />
            <h2 style={{ color: "#0569D8" }}>
              Tekifit
              <br />
              <span style={{ color: "#00499A" }}>Premium</span>
            </h2>
          </div>
        </div>
        <div
          className="premium__banner d-flex flex-column flex-sm-column flex-md-row flex-lg-row justify-content-center text-center"
          style={{ textAlign: "center" }}
        >
          <div className="premium__header">
            <div className="premium__header_box">
              <h2 className="highlighted text-center">{tech.pre_title}</h2>
              <h2 className="highlighted text-center">
                <span
                  className="highlighted text-center"
                  style={{ marginRight: "5px" }}
                >
                  {tech.highlighted_title}
                </span>
              </h2>
            </div>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
            <h4 className="d-flex justify-content-center ">
              <span style={{ color: "#00499A" }}>{tech.price}</span>
              <span>{tech.currency_code}</span>
            </h4>
            <div className="premium__list_item">
              <ul>
                {techItems.map((i, index) => (
                  <li key={index}>
                    <img src={check} alt="check" />
                    {i}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PremiumPage;
