import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";


import "../css/terms.css";

const PoliticaPagina = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("url.sale")} />
      <main
        role="main"
        class="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemtype="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>TÉRMINOS Y CONDICIONES DE LA PLATAFORMA “TEKIFIT” </h2>
            <br />

            <p>
              A continuación, presentamos los términos y condiciones aplicables
              a los Usuarios de la plataforma “Tekifit” (en adelante
              “Plataforma”) de la compañía Tekifit S.A.S. (en adelante, la
              “Compañía”), identificada bajo el dominio www.tekifit.com,
              donde el propósito es crear un espacio para poner en contacto al
              Usuario con otros Usuarios con el fin de que discutan, compartan e
              informen sobre temas especializados en tecnología, a través de la
              Plataforma con el uso de herramientas de inteligencia artificial,
              con el fin de crear una comunidad donde se discutan, se expliquen,
              se muestren temas especializados de tecnología, tal como es
              definido en este documento en la cláusula 5.
            </p>
            <p>
              Los términos y condiciones describirán la relación contractual
              entre Compañía, sociedad por acciones simplificada constituida
              bajo las leyes de la República de Colombia, identificada con
              Número de Identificación Tributaria NIT No. 901.393.213-6. En
              ellos explicaremos los derechos y obligaciones que adquieren los
              Usuarios mediante el uso de la Plataforma, o al utilizar alguno de
              nuestros Servicios por cualquier medio, y las reglas bajo las
              cuales se mantendrá la relación.
            </p>
            <p>
              Mediante el registro como Usuario, al hacer clic en “Unirme”,
              “Únete ya” u otro enlace similar, al acceder o utilizar al
              utilizar nuestros Servicios, y/o la aceptación electrónica expresa
              de los presentes términos y condiciones, los Usuarios manifiestan
              su consentimiento y aceptación sobre los mismos. Todo lo anterior,
              al realizar clic y/o doble clic en los enlaces que la Plataforma
              tienen dispuestos para los efectos. Si el Usuario no está conforme
              con estos términos y Condiciones, no deberá hacer clic en
              “Unirme”, u otro enlace similar y no deberá acceder ni utilizar de
              otro modo nuestros Servicios. Si así lo desea, el Usuario, puede
              poner fin a esta relación en cualquier momento, para ello, debe
              cerrar su cuenta y no acceder a nuestros Servicios ni usarlos.
            </p>
            <p>
              Así mismo, al efectuar alguna de las actividades mencionadas
              anteriormente, los Usuarios declaran que tienen capacidad jurídica
              y las facultades necesarias para obligarse de acuerdo con los
              presentes términos y condiciones.
            </p>
            <p>
              Los Usuarios deben leer detenidamente estos términos y
              condiciones, nuestra política de privacidad y tratamiento de datos
              personales y cualquier otro documento referenciado en estos
              términos y condiciones.
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                <b>1. Qué es Tekifit</b>
              </li>
              <br />
              <p>
                Tekifit es un espacio electrónico red social, para conectar
                a los Usuarios, aficionadas a la tecnología de todo el mundo,
                para fomentar la discusión sobre temas actuales del sector
                tecnológico, mostrar avances en tecnología propia, ayudarles a
                ser más productivos y alcanzar nuevas metas. Los Servicios
                tienen la finalidad de crear una comunidad, entendiendo esta
                como, el hecho de conocer diferentes profesionales, intercambiar
                ideas, encontrar oportunidades.
              </p>
              <p>
                Así mismo, la Plataforma contiene un espacio de e-learning donde
                los Usuarios encontrarán Contenido educativo para fortalecer sus
                habilidades tecnológicas y otro espacio en el que los
                Proveedores del sector tecnológicos podrán publicitar sus
                productos a través de Tekifit.{" "}
              </p>
              <br />
              <li>
                <b>2. Definiciones</b>
              </li>
              <br />
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. <b>Anuncio Publicitario:</b> significa un mensaje comercial
                  o pieza de comunicación que se crea con el objetivo claro de
                  promocionar un producto, un servicio.
                </li>
                <br />
                <li>
                  b. <b>Contenido:</b> significa todo aquello que los Usuarios o
                  la Compañía publiquen en la Plataforma, incluyendo, pero sin
                  limitarse a: respuestas u opiniones, publicaciones, videos, o
                  imágenes que compartan, enlaces a otras páginas web, etc.
                </li>
                <br />
                <li>
                  c. <b>Plataforma:</b> es el sitio web, aplicación o desarrollo
                  informático, independientemente de su denominación, que
                  ofrecerá a los Usuarios el acceso a los Servicios. El nombre
                  de la Plataforma, su formato y funcionamiento, podrá variar
                  según lo estime conveniente la Compañía.
                </li>
                <br />
                <li>
                  d. <b>Portal de Contacto:</b> es aquel que, por medio de una
                  plataforma electrónica, acerca a diferentes Usuarios para los
                  fines que dispone este documento.
                </li>
                <br />
                <li>
                  e. <b>Servicios:</b> son los Servicios definidos en la
                  cláusula 5 de estos términos y condiciones, consistente en una
                  red social para aficionados a la tecnología, con contenido
                  educativo y servicios publicitarios.{" "}
                </li>
                <br />
                <li>
                  f. <b>Usuarios:</b> Se clasifican en los siguientes:{" "}
                </li>
                <br />
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. <b>Anunciante:</b> son las personas que por ser
                    proveedores de insumos de tecnología desean publicitar sus
                    productos o servicios a través de la Plataforma.
                  </li>
                  <br />
                  <li>
                    ii. <b>Miembro:</b> son las personas que se registran y
                    abren una cuenta en la Plataforma.
                  </li>
                  <br />
                  <li>
                    iii.<b>Profesionales:</b> son las personas que en su
                    condición de profesionales de cierta materia, deciden subir
                    Contenido educativo a la Plataforma.{" "}
                  </li>
                  <br />
                  <li>
                    iv. <b>Visitante:</b> son aquellos sujetos que simplemente
                    visitan la Plataforma con el propósito de conocer los
                    Servicios u obtener información que se ofrezca en la
                    Plataforma.
                  </li>
                </ul>
              </ul>
              <br />
              <br />
              <li>
                <b>3. Objeto</b>
              </li>
              <br />
              <p>
                Al registrarse en la Plataforma como Usuario, al aceptar los
                presentes términos y condiciones y/o al utilizar nuestros
                Servicios, los Usuarios están contratando libre y
                voluntariamente un servicio de Portal de Contacto, un servicio
                de educación virtual no formal, o un servicio de publicidad,
                según corresponda. Las condiciones de estos servicios incluyen
                estos términos y condiciones y cualquier otro documento
                vinculante que esté publicado en la Plataforma, o se le dé a
                conocer al Usuario por cualquier otro medio, los cuales estarán
                disponibles para consulta, incluyendo, pero sin limitarse a la
                Política de Privacidad y Tratamiento de Datos Personales.
              </p>
              <br />
              <li>
                <b>4. Requisitos para acceder a los Servicios</b>
              </li>
              <br />
              <p>
                Para que los Usuarios puedan dar acceder a los Servicios, deben
                cumplir como mínimo con los siguientes requisitos.
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. Tener la capacidad jurídica para contratar según la ley del
                  país donde resida el Usuario.
                </li>
                <br />
                <li>
                  b. Contar con los recursos necesarios para pagar la
                  contraprestación por el Servicio de publicidad.
                </li>
                <br />
                <li>c. Haber leído estos términos y condiciones.</li>
                <br />
                <li>
                  d. Todos los que la Plataforma considere necesarios y sean
                  previamente informados a los Usuarios.
                </li>
              </ul>
              <br />
              <br />
              <li>
                <b>5. Descripción de los Servicios</b>
              </li>
              <br />
              <p>La Plataforma ofrece los siguientes Servicios: </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. <b>Servicio de Portal de Contacto:</b> La Plataforma ofrece
                  el servicio de Portal de Contacto, es decir que permite a los
                  Usuarios acercarse los unos a los otros e interactuar con el
                  Contenido que se encuentre dentro de la Plataforma, con el
                  propósito de crear una comunidad en torno a temas
                  exclusivamente tecnológicos. La Plataforma ayuda a los
                  Usuarios a encontrar y conectar con personas, grupos,
                  negocios, organizaciones y otras entidades de tecnología que
                  le interesen mediante la Plataforma.
                </li>
                <br />
                <li>
                  b. <b>Servicio de e-learning y eventos virtuales:</b> La
                  Plataforma ofrece un espacio de e-learning, en el que
                  diferentes profesionales de la industria de la tecnología,
                  podrán crear eventos o subir videos con findes educativos.
                </li>
                <br />
                <li>
                  c. <b>Servicio de Publicidad:</b> Este servicio permite a los
                  Anunciantes publicitar sus productos a través de la
                  Plataforma.
                </li>
              </ul>
              <br />
              <br />
              <li>
                <b>
                  6. Sobre el proceso de registro de los Usuarios y reglas
                  relativas a la Cuenta
                </b>
              </li>
              <br />
              <p>
                Para acceder a los Servicios que se ofertan en la Plataforma,
                los Usuarios deberán crear una cuenta en la Plataforma. Los
                Visitantes podrán ver el Contenido dispuesto por otros Usuarios
                en la Plataforma, sin embargo, no podrán generar ningún tipo de
                interacción en la Plataforma.{" "}
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. <b>Términos y condiciones generales del registro</b>{" "}
                </li>
                <br />
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Al momento de acceder a Plataforma los Usuarios deben
                    diligenciar el formulario de registro en todos sus campos,
                    con datos válidos, para poder utilizar todos los Servicios
                    que presta la Plataforma. La Plataforma podrá requerir en
                    cualquier momento, la información que considere necesaria
                    para verificar la información suministrada.
                  </li>
                  <br />
                  <li>
                    ii. Todo Usuario deberá completar la información requerida
                    de manera exacta, precisa y verdadera, y asume el compromiso
                    de actualizar sus datos siempre que sea necesario. Para
                    dichos efectos, y previo al recaudo de los respectivos datos
                    personales, los Usuarios deberán leer y conocer la Política
                    de Protección de Datos Personales de la Plataforma y
                    autorizar el tratamiento de sus datos de conformidad con
                    dicha política.
                  </li>
                  <br />
                  <li>
                    iii. Los Usuarios garantizarán y serán responsables, en
                    cualquier caso, de la veracidad, exactitud, vigencia y
                    autenticidad de los datos personales que suministren.{" "}
                  </li>
                  <br />
                  <li>
                    iv. Los Usuarios responderán por cualquier perjuicio, daño o
                    inconveniente generado por el suministro de información
                    falsa, errónea e imprecisa.
                  </li>
                  <br />
                  <li>
                    v. Si la información personal de los Usuarios presenta algún
                    cambio que llegare a afectar alguno de los Servicios
                    ofrecidos por la Plataforma, los Usuarios deberán
                    actualizarla a través de los medios dispuestos para ello.
                  </li>
                  <br />
                  <li>
                    vi. Los Usuarios aceptan y reconocen que la Compañía
                    utilizará como método principal de comunicación la dirección
                    de correo electrónico registrada en la cuenta.
                  </li>
                  <br />
                  <li>
                    vii. Los Usuarios aceptan que las cuentas o perfiles creados
                    podrán ser deshabilitados o limitados si la Plataforma
                    determina que se ha suministrado información incorrecta,
                    falsa o imprecisa, o se ha hecho un uso inadecuado de la
                    Plataforma.
                  </li>
                  <br />
                  <li>viii. La cuenta es personal, única e intransferible. </li>
                  <br />
                  <li>
                    ix. Los Usuarios accederán a su cuenta mediante el ingreso
                    del correo electrónico registrado en la Plataforma y clave
                    de seguridad personal elegida. Los Usuarios se obligan a
                    mantener la confidencialidad de su clave de seguridad y a
                    tomar las medidas de seguridad necesarias para que no ocurra
                    ningún incidente de seguridad respecto del manejo de su
                    cuenta y la respectiva clave de seguridad que le asiste. La
                    Compañía no se hará responsable por ninguna pérdida o daño
                    que surja como resultado de la inseguridad de su cuenta.
                  </li>
                  <br />
                  <li>
                    x. La Compañía podrá cancelar una cuenta existente por
                    cualquier motivo, a su entera discreción.
                  </li>
                  <br />
                  <li>
                    xi. Las cuentas de los Usuarios no podrán ser cedidas o
                    transferidas, ni tampoco podrán estar sujetas a cualquier
                    otra operación comercial que no sea reconocida expresamente
                    por los presentes términos y condiciones. los Usuarios se
                    hacen responsables por cualquier uso no autorizado que haga
                    un tercero, sea hecho voluntaria o involuntariamente, o de
                    cualquier tercero que pueda acceder a sus credenciales de
                    acceso, por hechos realizados por los Usuarios catalogados
                    hasta por culpa levísima. Los Usuarios se comprometen a
                    notificar a la Compañía en forma inmediata, por un medio
                    idóneo y fehaciente, de cualquier uso no autorizado de su
                    cuenta, así como el ingreso por terceros no autorizados a la
                    misma.
                  </li>
                  <br />
                  <li>
                    xii. El Usuario es el único responsable de la actividad y
                    Contenido administrado desde su cuenta.
                  </li>
                </ul>
                <br />
                <li>
                  b.{" "}
                  <b>
                    Causales de rechazo, suspensión y/o inhabilitación de
                    inscripción de cuentas:
                  </b>{" "}
                  La Compañía podrá cancelar, suspender o inhabilitar cualquier
                  cuenta, cuando ocurra alguno de los siguientes eventos:{" "}
                </li>
                <br />
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Será cancelada toda cuenta, donde se encuentre
                    información falsa, incorrecta, equivocada, imprecisa,
                    errónea o confusa.
                  </li>
                  <br />
                  <li>
                    ii. Serán suspendidas las cuentas de un Usuario cuando un
                    Usuario incumpla alguna de sus obligaciones respecto de la
                    Plataforma. En caso de incumplir por más de tres (3)
                    ocasiones, la cuenta será cancelada. Sin perjuicio de lo
                    anterior, podrá proceder la suspensión temporal de una
                    cuenta, en tanto se constate la existencia de un indicio que
                    dé cuenta de un potencial incumplimiento o vulneración a las
                    disposiciones legales o a los presentes términos y
                    condiciones.
                  </li>
                  <br />
                  <li>
                    iii. Cuando se realice una operación ilegal o que atente
                    contra la seguridad de bienes o personas, a través de una
                    cuenta registrada, tal cuenta será cancelada, y de ser el
                    caso, se avisará a las autoridades competentes de la
                    irregularidad presentada.
                  </li>
                  <br />
                  <li>
                    iv. Será suspendida temporalmente, toda cuenta que utilice
                    un lenguaje inapropiado en el desarrollo de sus
                    interacciones. Por lenguaje inapropiado, se entenderá aquel
                    que sea irrespetuoso, afecte la honra y buen nombre de las
                    personas.
                  </li>
                </ul>
              </ul>
              <br />
              <br />
              <li>
                <b>7. Sobre el uso del servicio de Portal de Contacto</b>
              </li>
              <br />
              <p>
                Para utilizar los servicios de Portal de Contacto, en el que los
                Usuarios pueden crear una red social para comunicarse con
                amigos, familiares, colegas y otras personas con respecto a
                temas de tecnología, consultarles su opinión o generar un debate
                meramente académico y profesional, crear eventos o grupos, o
                agregar Contenido a su perfil, los Usuarios deberán cumplir con
                lo siguiente:
              </p>
              <br />
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. <b>Términos y condiciones generales del servicio</b>
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Los Usuarios podrán libremente elegir con que Usuarios
                    conectan.{" "}
                  </li>
                  <br />
                  <li>
                    ii. Los Usuarios serán libres de publicar el Contenido,
                    siempre que sus publicaciones cumplan con las normas de la
                    comunidad.{" "}
                  </li>
                  <br />
                  <li>
                    iii. La Plataforma permite un amplio margen de interacción
                    entre los Usuario, sin embargo, estos declaran y reconocen
                    que la Compañía no está vinculada más que por estos términos
                    y condiciones a los Miembros, y por tanto no es responsable
                    del Contenido de ningún Miembro, ni de las afectaciones o
                    perjuicios que puedan causar las interacciones entre los
                    Usuarios.
                  </li>
                  <br />
                  <li>
                    iv. Los Usuarios podrán denunciar el Contenido de otro
                    Usuario cuando considere que es inapropiado, ofensivo,
                    delictivo, contrario al orden público o a la moral, y la
                    Compañía evaluará en un término de 15 días, si elimina o no
                    el Contenido denunciado.{" "}
                  </li>
                  <br />
                  <li>
                    v. La Compañía se reserva el derecho de permitir o eliminar
                    definitivamente de la Plataforma el Contenido de los
                    Usuarios, sin que por ello exista algún tipo de
                    responsabilidad de la Compañía en favor de los Usuarios.{" "}
                  </li>
                  <br />
                  <li>
                    vi. Los Usuarios aceptan y reconocen que la Compañía utiliza
                    los datos que deja el Usuario en la Plataforma, y recolecta
                    información de su comportamiento para hacer sugerencias, por
                    ejemplo, grupos a los que puede unirse el Usuario, eventos a
                    los que puede asistir el Usuario, páginas que puede seguir o
                    a las que puede enviar mensajes el Usuario, programas que
                    puede ver y personas que quizás quiera incluir en su lista
                    de amigos el Usuario.{" "}
                  </li>
                  <br />
                  <li>
                    vii. No existe entre el Usuario y la Compañía, una relación
                    de consumo, por lo cual no serán aplicables las normas sobre
                    protección al consumidor.{" "}
                  </li>
                </ul>
                <br />
                <li>
                  b. <b>Precio del servicio de Portal de Contacto</b>{" "}
                </li>
                <p>
                  El servicio de Portal de Contacto es gratuito. Además Se
                  tendrán paquetes Premiums de pago para la sección de “Improve
                  your skills” que podran pagarse mediante el “Tab Premium” de
                  la página Web.
                </p>
              </ul>
              <br />
              <br />
              <li>
                <b>
                  8. Sobre el uso del servicio de e-learning y eventos virtuales
                </b>
              </li>
              <br />
              <p>
                La Plataforma tiene dispuesto un espacio de e-learning donde los
                Profesionales en el área de tecnología, pueden subir Contenido
                educativo que los Usuarios pueden encontrar a través de los
                buscadores de la Plataforma de acuerdo a las palabras claves que
                ingrese. Así mismo, los Usuarios podrán acceder a eventos
                creados por otros Usuarios.{" "}
              </p>
              <br />
              <ul style={{ listStyle: "none" }}>
                <li>
                  <b>
                    a. Términos y condiciones generales aplicables a los
                    Miembros
                  </b>
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Los Miembros tendrán a su disposición diversas categorías
                    para poder seleccionar el tema donde quieran profundizar sus
                    habilidades tecnológicas
                  </li>
                  <br />
                  <li>
                    ii. Los miembros aceptan y reconocen que la Compañía no
                    tiene ninguna responsabilidad sobre el Contenido de los
                    Profesionales o de ningún otro Usuario, y que la Compañía no
                    verifica la idoneidad ni veracidad del Contenido publicado
                    por otros Usuarios.
                  </li>
                  <br />
                  <li>
                    iii. Los Miembros podrán denunciar cualquier Contenido de
                    e-learning que consideren inapropiado, erróneo, delictivo,
                    contrario al orden público o a la moral, y la Compañía
                    evaluará en un término de 15 días, si elimina o no el
                    Contenido denunciado.
                  </li>
                  <br />
                  <li>
                    iv. La Compañía únicamente proporciona el espacio
                    electrónico para subir este tipo de Contenido educativo o
                    para la creación de eventos on-line pero no hace ningún tipo
                    de garantía sobre la idoneidad ni veracidad del Contenido.{" "}
                  </li>
                </ul>
                <br />
                <li>
                  b.{" "}
                  <b>
                    Términos y condiciones generales aplicables a los
                    Profesionales
                  </b>
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Dentro de la Plataforma los Profesionales podrán subir
                    videos de contenido educativo o crear eventos.
                  </li>
                  <br />
                  <li>
                    ii. Los Profesionales serán los únicos responsables por el
                    Contenido que suban a la Plataforma y se obligan a mantener
                    indemne a la Compañía por cualquier daño o perjuicio que
                    reclame un Usuario en relación con el Contenido del
                    Profesional, y en consecuencia, se harán cargo de todos los
                    costos y gastos relacionados con la defensa del caso.{" "}
                  </li>
                  <br />
                  <li>
                    iii. La Plataforma se reserva el derecho de verificar la
                    idoneidad del Contenido de e-learning subido a la
                    Plataforma, sin que por ello exista la obligación de
                    hacerlo.{" "}
                  </li>
                </ul>
                <br />
                <li>
                  c. <b>Términos y condiciones generales del Servicios</b>
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. La Compañía no se responsabiliza por el Contenido
                    compartido en la sección de eventos y e-learning, ni tiene
                    ninguna responsabilidad para con los Usuarios.{" "}
                  </li>
                  <br />
                  <li>
                    ii. La Compañía no garantiza la idoneidad de los
                    Profesionales ni será responsable de las respuestas o
                    conocimiento que compartan los Profesionales a los Usuarios.
                  </li>
                  <br />
                  <li>
                    iii. No existe entre el Usuario y la Compañía, una relación
                    de consumo, por lo cual no serán aplicables las normas sobre
                    protección al consumidor.
                  </li>
                </ul>
              </ul>
              <br />
              <br />
              <li>
                <b>9. Sobre el uso del servicio de publicidad</b>
              </li>
              <br />
              <p>
                Los Anunciantes podrán adquirir el servicio de publicidad, a
                través del cual podrán solicitar a la Plataforma colocar un
                Anuncio Publicitario para los Usuarios, en relación a los
                intereses de los mismos, para incrementar el alcance y las
                ventas de sus productos o servicios.{" "}
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a.{" "}
                  <b>
                    Términos y condiciones generales aplicables a los Miembros
                  </b>{" "}
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Los Miembros aceptan y reconocen que la Compañía no tiene
                    ninguna responsabilidad sobre el producto o servicio que
                    publicita el Anunciante.
                  </li>
                  <br />
                  <li>
                    ii. En caso de no conformidad con los productos o servicios
                    del Anunciante, los Miembros deberán reclamar directamente a
                    estos últimos, y la Compañía deberá poner a disposición de
                    los Miembros toda la información del Anunciante su así lo
                    requieren.
                  </li>
                  <br />
                  <li>
                    iii. La Compañía no garantiza la idoneidad ni veracidad de
                    los Anuncios Publicitarios.{" "}
                  </li>
                  <br />
                  <li>
                    iv. La Compañía no es proveedor ni fabricante de los
                    servicios o productos del Anunciante, pues sólo es el medio
                    de comunicación a través del cual se transmite un anuncio
                    publicitario, en esa medida no le son aplicables las
                    disposiciones de protección al consumidor, salvo cuando se
                    demuestre solo o culpa grave de la Compañía.{" "}
                  </li>
                </ul>
                <br />
                <li>
                  b.
                  <b>
                    Términos y condiciones generales aplicables a los
                    Anunciantes
                  </b>
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. Los Anunciantes podrán parametrizar el alcance, la
                    audiencia, el presupuesto, entre otros factores de su
                    Anuncio Publicitario dentro de la Plataforma.{" "}
                  </li>
                  <br />
                  <li>
                    ii. Los Anunciantes serán los únicos responsables por la
                    veracidad e idoneidad de los Anuncios Publicitarios, y serán
                    los únicos responsables de sus obligaciones como proveedores
                    o fabricantes a la luz del régimen de protección al
                    consumidor.
                  </li>
                  <br />
                  <li>
                    iii. La Plataforma se reserva el derecho de suspender los
                    Anuncios Publicitarios, cuando identifique falsedad,
                    conductas contrarias a la ley o a las buenas costumbres, sin
                    que ello implique la devolución del dinero pagado por el
                    Anuncio.{" "}
                  </li>
                  <br />
                  <li>
                    iv. Es obligación de los Anunciantes diligenciar el
                    formulario de publicación de Anuncios Publicitarios en todos
                    sus campos, con datos válidos, para poder utilizar los
                    servicios que presta la Plataforma.
                  </li>
                  <br />
                  <li>
                    v. Los Anunciantes garantizarán y serán responsables, en
                    cualquier caso, de la veracidad, exactitud, vigencia y
                    autenticidad de los datos que suministren, así como de la
                    información entregada sobre los productos o servicios que
                    ofrezcan. En todo caso, la Plataforma podrá requerir en
                    cualquier momento, la información que considere necesaria
                    para verificar la información suministrada.
                  </li>
                  <br />
                  <li>
                    vi. Los Anunciantes mantendrán indemne a la Compañía y
                    responderán por cualquier perjuicio, daño o inconveniente
                    ocasionado a la Compañía o a un tercero, generado por el
                    suministro de información falsa, errónea e imprecisa.{" "}
                  </li>
                  <br />
                  <li>
                    vii. Si la información de los productos o servicios presenta
                    algún cambio frente al Anuncio Publicitario realizado en la
                    Plataforma, los Anunciantes deberán actualizarla a través de
                    los medios dispuestos para ello.
                  </li>
                  <br />
                  <li>
                    viii. Una vez enviada la información requerida, la
                    Plataforma realizará la publicación del Anuncio Publicitario
                    dentro de los 15 días hábiles siguientes a la recepción de
                    la información.
                  </li>
                </ul>
                <br />
                <li>
                  c.<b> Términos y condiciones generales del Servicios</b>
                </li>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    i. La Compañía no se responsabiliza por la veracidad del
                    Anuncio Publicitario ni por el estado o idoneidad de los
                    productos o servicios anunciados.{" "}
                  </li>
                  <br />
                  <li>
                    ii. No existe entre el Usuario y la Compañía, una relación
                    de consumo, por lo cual no serán aplicables las normas sobre
                    protección al consumidor, salvo que se compruebe dolo o
                    culpa grave de la Compañía, caso en el cual será
                    solidariamente responsable con el Anunciante.
                  </li>
                </ul>
                <br />
                <li>
                  d. <b>Precio de los servicios de Publicidad</b>
                </li>
                <br />
                <li>
                  e. <b>Pago y medios de pago</b>
                </li>
              </ul>
              <br />
              <br />
              <li>
                <b>
                  10. Disposiciones sobre el Contenido y Normas de la Comunidad
                </b>
              </li>
              <br />
              <p>
                Los Usuarios que accedan a los Servicios, aceptan y reconocen
                que otorgan a la Compañía una licencia de uso mundial, no
                exclusiva, gratuita, transferible y sublicenciable sobre el
                Contenido que comparta en la Plataforma.{" "}
              </p>
              <p>
                En ese sentido, la Compañía podrá usar, reproducir, modificar,
                adaptar, traducir, distribuir y publicar el Contenido, crear
                obras derivadas a partir de él, mostrarlo y exhibirlo en todo el
                mundo, por cualquier medio conocido y para cualquier fin
                legítimo.
              </p>
              <p>
                La Compañía no se hace responsable de las opiniones de los
                Usuarios por lo que el Usuario será el único responsable del uso
                que haga de su Contenido y del de otros Usuarios. La Compañía no
                edita ni controla los mensajes publicados o distribuidos en la
                Plataforma ni asumirá responsabilidad ni obligación alguna por
                dichos mensajes, que en todo caso, debe ceñirse a las normas de
                la comunidad.
              </p>
              <p>
                La Compañía se reserva el derecho de eliminar los mensajes y el
                Contenido de los Usuarios, cuando el contenido sea falso,
                ilegal, engañoso, injurioso, difamatorio, intimidatorio,
                discriminatorio o contrario a los derechos de terceros, o no
                esté alineado con la finalidad y propósito de la Plataforma.
              </p>
              <p>
                Al acceder o usar la Plataforma, los Usuarios podrían exponerse
                a Contenidos de otros Usuarios que podría resultar ofensivo,
                obsceno, impreciso, censurable o inadecuado. La Compañía no
                puede verificar dicho Contenido ni puede verificar su precisión,
                por eso el acceso a la Plataforma lo hacen los Usuarios por su
                cuenta y riesgo propio. No obstante lo anterior, los Usuarios
                podrán reportar el Contenido de otros Usuarios, y la Plataforma
                analizará la solicitud dentro de los 15 días hábiles, y tomará
                una decisión sobre si conservar o eliminar el Contenido
                reportado.{" "}
              </p>
              <p>
                Cuando los Usuarios compartan cualquier tipo de Contenido,
                incluido, pero no limitativo a imágenes, textos, dibujos,
                videos, música, o cualquier material protegido por normas de
                propiedad intelectual (derechos de autor) o industrial, lo hará
                porque cuenta con el consentimiento de sus legítimos titulares
                para utilizar este contenido, y en caso de no tenerlo, los
                Usuarios no podrán subir Contenido sujeto a derechos de
                propiedad intelectual o industrial. Si la Compañía recibe una
                reclamación en materia de propiedad intelectual, porque algún
                Usuario está mostrando contenido protegido sin autorización, se
                eliminará ese Contenido y se reserva el derecho a suspender o
                eliminar la cuenta que lo haya compartido, así mismo el Usuario
                estará obligado a mantener indemne a la Compañía y responder
                personalmente por la infracción a la propiedad intelectual de
                terceros, para lo cual, autoriza a la Compañía a compartir sus
                datos personales con el reclamante.{" "}
              </p>
              <p>
                En general, para el acceso a los Servicios, los Usuarios deberán
                cumplir las siguientes normas de la comunidad:{" "}
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. No compartir Contenido que sea contrario, menosprecie o
                  atente contra los derechos fundamentales y las libertades
                  constitucionales de los otros Usuarios.{" "}
                </li>
                <br />
                <li>
                  b. No compartir Contenido que induzca, incite o promueva
                  actuaciones delictivas, denigratorias, difamatorias,
                  infamantes, violentas o, en general, contrarias a la ley, a la
                  moral y buenas costumbres generalmente aceptadas o al orden
                  público.
                </li>
                <br />
                <li>
                  c. No compartir Contenido que induzca, incite o promueva
                  actuaciones, actitudes o pensamientos discriminatorios por
                  razón de sexo, raza, religión, creencias, edad o condición.
                </li>
                <br />
                <li>
                  d. No compartir Contenido que trate datos personales de
                  terceras personas sin su autorización, como nombres, fotos,
                  vídeos, teléfonos, enlaces de perfiles, etc.
                </li>
                <br />
                <li>
                  e. No compartir Contenido que pueda ser constitutivo de
                  delitos de opinión, como por ejemplo amenazas, difamación,
                  calumnias o injurias a personas, organizaciones, grupos,
                  empresas, instituciones, etc.
                </li>
                <br />
                <li>f. No suplantar la identidad de terceros</li>
                <br />
                <li>
                  g. No compartir Contenido que pueda ser considerado como
                  apología.{" "}
                </li>
                <br />
                <li>
                  h. Abstenerse de distribuir, intercambiar o difundir Contenido
                  que generen infracciones de copyright y propiedad intelectual.
                </li>
              </ul>
              <br />
              <p>
                No obstante lo anterior, el Usuario acepta mantener indemne a la
                Compañía en los términos de la cláusula de indemnidad de los
                prestes términos y condiciones.{" "}
              </p>
              <p>
                La Plataforma implementará un sistema de reportes, para que los
                Usuarios puedan reportar el Contenido que consideren que es
                abusivo y vulnera sus derechos fundamentales y podrá eliminar o
                suspender las cuentas que tengan un número significativo de
                reportes.
              </p>
              <br />
              <br />
              <li>
                <b>11. Obligaciones de los Usuarios</b>
              </li>
              <br />
              <p>
                Los Usuarios se comprometen a cumplir con todas las obligaciones
                que se expresan en los presentes Términos y Condiciones, así
                como aquellas que se determinen en otros documentos, incluyendo
                y sin limitarse a otros contratos que sean celebrados con la
                Compañía, así como la Política de Protección de Datos
                Personales. Además de estas obligaciones, los Usuarios, tendrán
                las siguientes obligaciones:{" "}
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. Dirigirse con respeto al personal de la Plataforma y a
                  otros Usuarios.
                </li>
                <br />
                <li>b. Pagar por los Servicios que no son gratuitos.</li>
                <br />
                <li>
                  c. Actualizar la información suministrada respecto de su
                  identidad.
                </li>
                <br />
                <li>
                  d. Dar aviso a la Plataforma sobre cualquier irregularidad o
                  ilegalidad que tenga conocimiento que ocurra.
                </li>
                <br />
                <li>
                  e. Abstenerse de enviar información para realizar
                  publicaciones que infrinjan los derechos de propiedad
                  intelectual de terceros.{" "}
                </li>
                <br />
                <li>
                  f. Leer y conocer a cabalidad los presentes Términos y
                  Condiciones.
                </li>
                <br />
                <li>
                  g. Cumplir con los manuales de comunidad y todas las normas
                  expresadas en estos términos y condiciones o en otros
                  documentos.{" "}
                </li>
              </ul>
              <br />
              <br />
              <li>
                <b>12. Obligaciones de Tekifit</b>
              </li>
              <br />
              <p>
                En virtud de la naturaleza del servicio que ofrece la
                Plataforma, la misma se obliga a:{" "}
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. Proveer la infraestructura para el correcto funcionamiento
                  de los Servicios.{" "}
                </li>
                <br />
                <li>
                  b. Publicar los Anuncios Publicitarios de conformidad con
                  estos términos y condiciones.
                </li>
                <br />
                <li>
                  c. Permitir la consulta de la información de los Usuarios con
                  el propósito de presentar una queja o reclamo directamente al
                  Anunciante.{" "}
                </li>
                <br />
                <li>
                  d. Si la Plataforma llega a ser notificada por un Usuario
                  acerca de la existencia de información falsa, errónea,
                  inexacta, incompleta o que incumpla con estos términos y
                  condiciones, podrá realizar las averiguaciones e indagaciones
                  pertinentes a fin de corroborar las aseveraciones que fueron
                  conocidas. En caso de evidenciar tales fallas o tener
                  razonable sospecha sobre su veracidad, autenticidad o
                  verificabilidad, podrá deshabilitar las cuentas comunicándolo
                  al Usuario. No obstante lo anterior, la Compañía no realizará
                  ningún reembolso de dinero pagado en ninguna transacción así
                  como tampoco responderá por daños y perjuicios.{" "}
                </li>
              </ul>
              <br />
              <br />
              <li>
                <b>13. Garantía y calidad en los Servicios </b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan y reconocer que Tekifit es una
                Plataforma en la modalidad red social, que conecta a los
                Usuarios para generar interacción entre ellos, pero que no tiene
                ningún tipo de vinculación con los Miembros, de modo que no está
                en capacidad de garantizar la idoneidad, veracidad o eficacia de
                los Contenidos, así como tampoco es el proveedor o fabricante de
                los productos o servicios publicitados en la Plataforma, de modo
                que tampoco puede garantizar la veracidad del Anuncio
                Publicitario o la calidad o idoneidad de los productos o
                servicios que publicitan los Anunciantes a través de la
                Plataforma.{" "}
              </p>
              <p>
                No obstante lo anterior, los Anunciantes se obligan a cumplir
                con todas las normas del Estatuto de Protección al Consumidor,
                en caso que se configure una relación de consumo, y en esa
                medida, deberá hacerse responsable directamente por los daños,
                perjuicios, y garantías que reclamen los otros Usuarios en su
                contra.{" "}
              </p>
              <p>
                La Plataforma hará sus mejores esfuerzos para prestar los
                Servicios con la calidad que esperan los Usuarios, sujeto a los
                presente términos y condiciones.
              </p>
              <p>
                No obstante lo anterior, los Servicios que presta la Plataforma
                no tienen ninguna garantía, y se prestan conforme a los términos
                de la cláusula 14 de estos términos y condiciones.{" "}
              </p>
              <p>
                Respecto de la garantía de calidad en el Servicio de Publicidad,
                la Compañía únicamente garantiza que el Anuncio Publicitario
                salga al público de acuerdo con los parámetros que configuró el
                Anunciante, pero no garantiza ninguna efectividad del Anuncio
                Publicitario. De modo que la Compañía sólo aceptará reclamos en
                los casos en que el Anuncio Publicitario no haya salido de
                conformidad con la configuración del Anunciante.
              </p>
              <br />
              <br />
              <li>
                <b>14. Servicio “As Is”</b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan que los Servicios que presta la Plataforma
                es un servicio “as is” (tal cual), lo que quiere decir que no
                existe garantía alguna expresa ni implícita sobre la prestación
                del servicio de la Plataforma, salvo la garantía contemplada en
                el último párrafo de la cláusula 13 de este documento.{" "}
              </p>
              <p>
                Ningún tipo de información que los Usuarios hayan recibido por
                algún medio por parte de la Compañía o cualquiera de sus
                representantes o funcionarios, adicional a estos términos y
                condiciones, comprometerá la responsabilidad de la Compañía ni
                representará garantía alguna sobre los Servicios.{" "}
              </p>
              <p>
                Por tal motivo, el único recurso que los Usuarios tendrá en
                relación con los Servicios que presta la Plataforma, será el de
                cancelar su cuenta y no volver a usar la Plataforma.
              </p>
              <br />
              <br />
              <li>
                <b>15. Peticiones, quejas, reclamos y sugerencias</b>
              </li>
              <br />
              <p>
                Las peticiones, quejas, reclamos y sugerencias (en adelante
                “PQRS”) a los Servicios de la Plataforma se regirán por las
                siguientes disposiciones:
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. Las PQRS de los Usuarios podrán interponerse a través del
                  formulario de contacto o al correo electrónico:
                  santiago@techfrenetic.com
                </li>
                <br />
                <li>
                  b. El Usuario o un tercero en su representación, previa
                  identificación, podrá presentar sus reclamaciones directas de
                  forma gratuita, en la Plataforma u otros medios que esta
                  disponga.{" "}
                </li>
                <br />
                <li>
                  c. La Plataforma registrará e individualizará la reclamación
                  asignándole un número a su reclamación, que será puesto en
                  conocimiento de quien presentó la misma, a través de una copia
                  o informándole el número de ésta.
                </li>
                <br />
                <li>
                  d. La Plataforma podrá trasladar la PQRS a un tercero, cuando
                  la misma trate sobre información o un servicio no prestado
                  directamente por la Plataforma.{" "}
                </li>
                <br />
                <li>
                  e. En caso de que la respuesta le corresponda a la Plataforma,
                  la misma se deberá dentro de los siguientes quince (15) días
                  hábiles.
                </li>
              </ul>
              <br />
              <br />
              <li>
                <b>16. Responsabilidad de Tekifit</b>
              </li>
              <br />
              <p>
                La Compañía informa a los Usuarios que quedará exenta de
                responsabilidad cuando:{" "}
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  a. Los Usuarios proporcionen datos de identidad o información
                  incorrectos, inexactos, no actualizados o falsos, y en
                  particular, aunque de modo no exclusivo ni limitativo, por
                  daños y perjuicios de cualquier naturaleza que se puedan deber
                  a suplantación de personalidad de un tercero, efectuada por un
                  Usuario en cualquier clase de comunicación o transacción
                  realizada a través de la Plataforma.{" "}
                </li>
                <br />
                <li>
                  b. Por consecuencias derivadas del contenido de la información
                  publicada por los Usuarios, sin perjuicio de que la Plataforma
                  pueda limitar o borrar cualquier Contenido a su discreción.{" "}
                </li>
                <br />
                <li>
                  c. Cuando se produzca suspensión, interrupción o corte de los
                  Servicio debido a mantenimiento correctivo o preventivo.
                </li>
                <br />
                <li>
                  d. Cuando se deba realizar modificaciones urgentes que sean
                  necesarias en la Plataforma por causas ajenas a la voluntad de
                  la Compañía en casos de congestionamiento de magnitud en las
                  líneas o señales, casos de fuerza mayor o fortuitos
                  atribuibles a la naturaleza, a las condiciones atmosféricas, a
                  altas o bajas en el voltaje del sistema eléctrico y otros de
                  similar naturaleza.
                </li>
                <br />
                <li>
                  e. La Plataforma no ofrece garantías sobre los Anuncios
                  Publicitarios ni sobre los productos o servicios subyacentes.
                </li>
                <br />
                <li>
                  f. La Plataforma no ofrece garantías sobre los Contenidos de
                  e-learning ni sobre los eventos.{" "}
                </li>
                <br />
                <li>
                  g. Por virus importados a través de la red o cualquiera sea su
                  origen.{" "}
                </li>
                <br />
                <li>
                  h. Por el uso inadecuado de los Servicios por parte del
                  Usuario, ya sea por desconocimiento del uso de equipos, mala
                  configuración de estos, fallas técnicas de acceso u otras
                  similares en los equipos de propiedad de los Usuarios.{" "}
                </li>
                <br />
                <li>
                  i. Por pérdida de ganancias por negocios no realizados por los
                  Usuarios, lucro cesante, pérdidas pecuniarias o pérdidas de
                  información o datos, relacionados con los Anuncios
                  Publicitarios.{" "}
                </li>
                <br />
                <li>
                  j. Por cualquier costo, perjuicio o daño que sea causado a los
                  Usuarios, como consecuencia de la utilización indebida de los
                  Servicios por personas ajenas a la Plataforma.{" "}
                </li>
                <br />
                <li>
                  k. Por las infracciones de derechos de propiedad intelectual
                  efectuadas por los Usuarios, quienes se atribuyan la condición
                  de derecho habientes, ya sea como titulares o licenciatarios
                  de tales derechos, o quienes presuman contar con cualquier
                  tipo de autorización para su uso.
                </li>
              </ul>
              <p>
                En cualquier caso, la responsabilidad de la Compañía está
                limitada al valor pagado por los Servicios durante el periodo de
                12 meses anterior al evento que dio lugar a la responsabilidad,
                o de 300 dólares americanos si el Usuario no ha hecho ningún
                pago.
              </p>
              <br />
              <br />
              <li>
                <b>17. Indemnidad</b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan mantener indemne, defender, indemnizar y
                eximir de responsabilidad a la Compañía de cualquier
                reclamación, responsabilidad, daño, pérdida y gasto, incluidos,
                entre otros, honorarios legales, que surjan de o estén
                relacionados de alguna manera con el incumplimiento de estos
                términos y condiciones, el uso inadecuado de la Plataforma, la
                responsabilidad de los Anunciantes, y en general sobre cualquier
                incumplimiento que exista entre un Usuario y otro, incluyendo,
                pero sin limitarse a: cualquier lesión, pérdida o daño de
                cualquier tipo que surja en relación con o como resultado de
                dicho incumplimiento.
              </p>
              <p>
                Así mismo, los Usuario aceptan mantener indemne, defender,
                indemnizar y eximir de responsabilidad a la Compañía de
                cualquier reclamación, responsabilidad, daño, pérdida y gasto,
                incluidos, entre otros, honorarios legales, que surjan de o
                estén relacionados de alguna manera con sus interacciones en la
                Plataforma.{" "}
              </p>
              <br />
              <br />
              <li>
                <b>18. Acuerdo de niveles de servicio</b>
              </li>
              <br />
              <p>
                La Compañía se compromete a mantener una disponibilidad de la
                Plataforma del 99.9%.
              </p>
              <br />
              <br />
              <li>
                <b>19. Fallas en la Plataforma</b>
              </li>
              <br />
              <p>
                La Compañía no se responsabiliza por cualquier daño, perjuicio o
                pérdida causados por fallas en la Plataforma derivadas del
                servidor o Internet. La Compañía tampoco será responsable por
                cualquier virus que pudiera infectar el equipo de los Usuarios
                como consecuencia del acceso o uso de la Plataforma o a raíz de
                cualquier transferencia de datos, archivos, imágenes, textos, o
                audios contenidos en el mismo. Los Usuarios no podrá imputarle
                responsabilidad alguna ni exigir pago por lucro cesante, en
                virtud de perjuicios resultantes de dificultades técnicas o
                fallas en los sistemas de Internet. La Compañía no garantiza el
                acceso y uso continuado o ininterrumpido de su Plataforma por
                encima de lo indicado en los Acuerdos de Niveles de Servicio.{" "}
              </p>
              <p>
                La Plataforma puede eventualmente no estar disponible debido a
                dificultades técnicas o fallas de Internet, o por cualquier otra
                circunstancia ajena a La Compañía; en tales casos se procurará
                restablecerla con la mayor celeridad posible sin que por ello
                pueda imputársele algún tipo de responsabilidad a La Compañía.
                La Compañía no será responsable por ningún error u omisión que
                los Usuarios cometa dentro de la Plataforma.
              </p>
              <br />
              <br />
              <li>
                <b>
                  20. Hipervínculos a otros sitios web y uso de cookies o
                  tecnologías análogas
                </b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan que la Compañía puede hacer uso de cookies
                y/u otras tecnologías similares, tanto en la Plataforma, sus
                contenidos, servicios, como en los correos electrónicos enviados
                a los Usuarios, con la finalidad de, entre otras, propósitos de
                autenticación, registrar las actividades en la Plataforma,
                mejorar la funcionalidad de la misma, optimizar los Servicios
                ofrecidos, analizar tendencias del mercado, analizar la
                información demográfica de quienes visitan la Plataforma y hacen
                uso de los servicios que el mismo ofrece, evaluar la eficacia de
                la publicidad del mismo, comportamiento de consumo de los
                compradores de la Plataforma y resultado de las actividades allí
                ejecutadas, determinar quién ha abierto el correo electrónico
                enviado y el formato en que lo hace. Estas herramientas permiten
                la obtención de, entre otras, información referente al tipo de
                navegador y el sistema operativo que se utiliza, la dirección
                IP, el tiempo que se permanece en la Plataforma y el número de
                visitas realizadas a la Plataforma, el uso de la misma, entre
                otros.{" "}
              </p>
              <p>
                Los Usuarios puede configurar su navegador para deshabilitar y
                eliminar las cookies y/u otras tecnologías similares, en cuyo
                caso, a pesar de que se podrá continuar visitando la Plataforma,
                el acceso a determinadas características de la Plataforma se
                pueden restringirse.
              </p>
              <p>
                Al usar nuestros Servicios, los Usuarios aceptan que la
                Plataforma pueda mostrarle anuncios que considere que le
                resultarán relevantes para el Usuario y sus intereses, y acepta
                que la Plataforma use sus datos personales como ayuda para
                determinar qué anuncios mostrar.
              </p>
              <br />
              <br />
              <li>
                <b>21. Propiedad Intelectual </b>
              </li>
              <br />
              <p>
                La Plataforma, las soluciones y los diseños, gráficos, textos,
                imágenes, código fuente y código objeto, modelo de uso y demás
                contenido al que los Usuarios tiene acceso para el acceso a los
                Servicios, es de propiedad de la Compañía y está protegido por
                la propiedad intelectual e industrial según corresponda.
              </p>
              <p>
                Los signos distintivos, tales como marcas, nombres comerciales,
                logotipos, y todos los componentes de los signos distintivos de
                la Compañía, son propiedad exclusiva de la misma, por lo cual
                los Usuarios no tiene el derecho a usar las marcas sin
                autorización.
              </p>
              <p>
                La utilización de los aplicativos y del software de la
                Plataforma por parte de los Usuarios, no implica cesión alguna
                de los derechos de propiedad industrial y de derechos de autor
                de la Compañía sobre tales contenidos, ni sobre la Plataforma.
                Los usos autorizados estarán delimitados por los presentes
                términos y condiciones, la finalidad natural y funcional de los
                aplicativos y del software de la Plataforma y la relación legal
                entre los Usuarios y la Compañía. Los usos conferidos deben
                interpretarse de manera restrictiva.
              </p>
              <p>
                Los Usuarios y cualquier tercero deben abstenerse de extraer y/o
                reutilizar partes del contenido sin el consentimiento previo y
                expreso de la Compañía y/o sus respectivos titulares, so pena de
                indemnizar a la Compañía por cualquier daño o perjuicio causado
                con el incumplimiento de esta sección.
              </p>
              <br />
              <br />
              <li>
                <b>22. Tratamiento de datos personales</b>
              </li>
              <br />
              <p>
                Con la aceptación de los presentes términos y condiciones, la
                Compañía queda autorizada para la recopilación, uso y
                tratamiento de los datos personales contenidos en la Plataforma
                y aquellos que se llegaren a suministrar o recopilar en el
                futuro, para las finalidades y en los términos descritos en la
                Política de Tratamiento de Datos Personales de la Compañía.
              </p>
              <p>
                En cualquier caso, los Usuarios podrán ejercer sus derechos a
                conocer, actualizar, rectificar o suprimir los datos o a revocar
                esta autorización en los términos de la Política de Tratamiento
                de Datos Personales.{" "}
              </p>
              <p>
                La Compañía podrá pedirle a los Usuarios algunos Datos
                Personales tales como nombre, dirección, teléfono y correo
                electrónico, medios de pago. Al suministrar los datos por
                cualquier medio el Usuario otorga consentimiento expreso para
                que la Compañía lleve a cabo operaciones de Tratamiento de Datos
                Personales tales como recolección, almacenamiento, uso,
                circulación o supresión, en los términos de la Política de
                Tratamiento de Datos Personales. La Compañía podrá obtener datos
                también a través de la integración con Microsoft, Google,
                Facebook u otras plataformas, los cuales igualmente se sujetarán
                a los presentes términos y condiciones y en especial a la
                política de privacidad y tratamiento de datos Personales.{" "}
              </p>
              <p>
                Los datos personales serán sometidos a tratamiento para el
                desarrollo del objeto social de la Compañía y demás fines
                especificados en la política de tratamiento de datos personales
                de la Compañía.
              </p>
              <p>
                La Compañía tratará los datos personales, según sean definidos
                en la política de privacidad y tratamiento de datos personales
                de la Compañía, durante el tiempo que sea necesario para cumplir
                con las finalidades mencionadas, y/o mientras sea necesario para
                el cumplimiento de obligaciones legales o contractuales.
              </p>
              <br />
              <br />
              <li>
                <b>23. Plazo y Terminación</b>
              </li>
              <br />
              <p>
                Estos términos y condiciones y los demás documentos vinculantes,
                aplicarán a la relación con la Compañía, mientras el Usuario use
                los Servicios que ofrece la Plataforma.
              </p>
              <p>
                En el evento en que la Compañía determine que el Usuario ha
                infringido cualquiera de las condiciones dispuestas por este
                documento, podrá terminar o suspender inmediatamente la
                vinculación con la Compañía.
              </p>
              <br />
              <br />
              <li>
                <b>24. Fuerza mayor y caso fortuito </b>
              </li>
              <br />
              <p>
                Ni los Usuarios ni la Compañía, serán responsables de
                incumplimiento, o retraso en el cumplimiento de las
                obligaciones, en caso de que se presente un evento de fuerza
                mayor, es decir que este sea irresistible e imprevisible, y que
                se encuentre más allá del control razonable tanto del Usuario
                como de la Compañía, incluyendo, pero sin limitarse a la
                expedición de una ley, un acto de autoridad, una inundación, un
                terremoto, una pandemia, etc.
              </p>
              <p>
                Tampoco serán responsable, ni el Usuario ni la Compañía, en el
                evento en que se presente un caso fortuito, que implique el
                hecho de un tercero que se torna imprevisible e irresistible y
                jurídicamente ajeno a la parte que lo alega, incluyendo, pero
                sin limitarse a una guerra, un acto malintencionado de terceros,
                etc.
              </p>
              <p>
                En cualquier caso, la fuerza mayor y el caso fortuito se
                considerarán eximentes de responsabilidad y suspenderán o
                extinguirán, según aplique, el cumplimiento de las obligaciones.
              </p>
              <br />
              <br />
              <li>
                <b>
                  25. Aceptación de los términos y condiciones y las
                  modificaciones de los mismos
                </b>
              </li>
              <br />
              <p>
                El uso de las funcionalidades incluidas en la Plataforma por
                parte de los Usuarios, implica la aceptación de los presentes
                Términos y Condiciones. Si cualquier Usuario está en desacuerdo
                con alguno de los elementos contenidos en los Términos y
                Condiciones, deberá cesar el uso de la Plataforma.
              </p>
              <p>
                Los presentes Términos y Condiciones constituyen un acuerdo
                legalmente vinculante ente los Usuarios y la Compañía.{" "}
              </p>
              <p>
                La Plataforma podrá realizar actualizaciones y cambios a los
                presentes Términos y Condiciones, tanto en su apariencia como en
                sus características y contenido, y este podrá ser cambiado o
                modificado en la medida en que se desarrollen nuevas funciones o
                características y a las condiciones de acceso en cualquier
                momento, sin que esto signifique compromiso adicional a los
                establecidos por parte de la Plataforma ni se alegue algún tipo
                de perjuicio.
              </p>
              <br />
              <br />
              <li>
                <b>26. Legislación aplicable</b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan que los presentes términos y condiciones se
                regirán por la legislación colombiana. En el análisis del
                presente documento, los documentos del mismo se interpretarán en
                el siguiente orden: (i) Los términos y condiciones (ii) La
                política de tratamiento de datos personales (iii) Cualquier otro
                documento escrito suscrito por el Usuario y por la Compañía con
                ánimo de que haga parte de los documentos vinculantes.
              </p>
              <br />
              <br />
              <li>
                <b>27. Separabilidad</b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan que cada una de las cláusulas del presente
                documento, tienen la condición de esenciales y el incumplimiento
                de cualquiera de ellas podrá dar lugar a la terminación del
                contrato que el Usuario celebre con la Compañía.
              </p>
              <p>
                Si alguna de las disposiciones de los documentos vinculantes es
                considerada inválida, ilegal o no ejecutable, sus disposiciones
                restantes, en ningún momento se verán afectadas o perjudicadas,
                y las disposiciones inválidas, ilegales y no ejecutables se
                volverán a enunciar en forma tal que reflejen la intención
                original del documento.
              </p>
              <br />
              <br />
              <li>
                <b>28. Resolución de conflictos </b>
              </li>
              <br />
              <p>
                Los Usuarios aceptan que para cualquier divergencia o
                discrepancia que se presente durante el uso de la Plataforma, el
                Usuario y la Compañía dispondrán de un término de treinta (30)
                días calendario, contados a partir de la fecha del reclamo, para
                solucionar sus diferencias acudiendo a la negociación directa o
                a la conciliación.{" "}
              </p>
              <p>
                Si pasado este tiempo, no podemos llegar a ningún acuerdo, tanto
                el Usuario como la Compañía, son libres de acudir a la justicia
                ordinaria.{" "}
              </p>
              <br />
              <p>
                Gracias por leer con atención nuestros términos y condiciones.
              </p>
              <br />
              <br />
              <p className=" d-flex justify-content-end">
                  Última actualización: 07 de Agosto de 2025
                </p>
             
                
             
            </ul>
          </div>
        </div>
        
      </main>
    </Layout>
  );
};

export default PoliticaPagina;
