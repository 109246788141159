import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const RefundPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>Refund policy</h2>
            <br />
            <p>
              Since the Service offers non-tangible, irrevocable goods we do not
              provide refunds after the product is purchased, which you
              acknowledge prior to purchasing any product on the Services.
              Please make sure that you’ve carefully read service description
              before making a purchase.
            </p>
            <br />
            <h2>Contacting us</h2>
            <br />
            <p>
              If you have any questions, concerns, or complaints regarding this
              refund policy, we encourage you to contact us using the details
              below:
            </p>
            <p>SANTIAGO@TECHFRENETIC.COM</p>
            <span>This document was last updated on January 07, 2025</span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default RefundPage;
