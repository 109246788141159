import React from "react";
import { useTranslation } from "react-i18next";

export default function ProfileViewers() {

  const { t } = useTranslation("common");

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 p-2">
      <div className="d-flex flex-column text-center viewers__cards">
        <img src="/images/temp/follow-10.png" alt="Juan Andrés Castro" />
        <span className="viewer__name">Jua Andrés Castro</span>
        <span className="viewer__position">Big Data Tekifit</span>
        {/* "btn-primary" */}
        <a href="/" className="btn btn-secondary">
        {t("profile.following")} 
        </a>
      </div>
    </div>
  );
}
